<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(this.action == 'insert' || this.action == 'copy')">{{ $t("COMMODITY-LIST.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(this.action == 'update')">{{ $t("COMMODITY-LIST.EDIT-ITEM") }}</MDBCardHeader>
        <MDBCardText class="pt-3">
          <MDBRow class="g-3">
            <MDBCol md="6">
              <MDBRow>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">序<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="name" placeholder="序" v-model="Form.soft_seq_no.value">
                    <span class="text-danger">{{ soft_seq_noError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">{{ $t('COMMON.PRODUCTNUMBER') }}<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" :placeholder="$t('COMMON.PRODUCTNUMBER')" v-model="Form.number.value" >
                    <span class="text-danger">{{ numberError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">版本區域<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="版本區域" v-model="Form.version_area.value" >
                    <span class="text-danger">{{ version_areaError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">{{ $t('COMMON.PRODUCTNAME') }}<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="name" :placeholder="$t('COMMON.PRODUCTNAME')" v-model="Form.name.value">
                    <span class="text-danger">{{ nameError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">{{ $t('COMMON.USEVERSION') }}<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="name" :placeholder="$t('COMMON.USEVERSION')" v-model="Form.version.value">
                    <span class="text-danger">{{ versionError }}</span>
                  </div>
                </MDBCol>
                
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">KeyPro<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="name" placeholder="KeyPro" v-model="Form.key_pro_version.value">
                    <span class="text-danger">{{ key_pro_versionError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">VersionNo<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="name" placeholder="VersionNo" v-model="Form.version_no.value">
                    <span class="text-danger">{{ version_noError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">NeedWrite<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" name="name" placeholder="NeedWrite" v-model="Form.need_write.value">
                    <span class="text-danger">{{ need_writeError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <!-- <MDBCol md="6">
              <MDBRow>
                <MDBCol md="12" v-if="action == 'insert'">
                  <div class="mb-3">
                    <label class="form-label text-dark">{{ $t('COMMON.PRODUCTTYPE') }}<span class="text-danger">*</span></label>
                    <select class="form-select" v-model="Form.type.value">
                      <option value="1">產品</option>
                      <option value="2">課程</option>
                    </select>
                    <span class="text-danger">{{ typeError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">{{ $t(`COMMON.SORT`) }}<span class="text-danger">*</span></label>
                    <input type="number" class="form-control" name="name" :placeholder="$t(`COMMON.SORT`)" v-model="Form.sort.value">
                    <span class="text-danger">{{ sortError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12" v-if="Form.type.value != 2">
                    <div class="mb-3">
                      <label class="form-label text-dark">{{ $t('COMMON.COMMODITYPEOPLE') }}<span class="text-danger">*</span></label>
                      <input type="number" class="form-control" :placeholder="$t('COMMON.COMMODITYPEOPLE')" v-model="Form.people.value">
                      <span class="text-danger">{{ peopleError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="12" v-if="Form.type.value != 2">
                    <div class="mb-3">
                      <label class="form-label text-dark">{{ $t('COMMON.COMMODITYCOUNT') }}<span class="text-danger">*</span></label>
                      <input type="number" class="form-control" :placeholder="$t('COMMON.COMMODITYCOUNT')" v-model="Form.count.value" >
                      <span class="text-danger">{{ countError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="12" v-if="Form.type.value != 2">
                    <div class="mb-3">
                      <label class="form-label text-dark">{{ $t('COMMON.COMMODITYAUTHORIZATION') }}<span class="text-danger">*</span></label>
                      <input type="number" class="form-control" :placeholder="$t('COMMON.COMMODITYAUTHORIZATION')" v-model="Form.authorization.value">
                      <span class="text-danger">{{ numberError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="12" v-if="Form.type.value != 2">
                    <div class="mb-3">
                      <div>
                        <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.COMMODITYAUTHORIZATIONSERVER') }}<span class="text-danger">*</span></label>  
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-free-1`" value="1" v-model="Form.authorization_server.value">
                        <label class="form-check-label" v-bind:for="`${Action}-authorization_server-1`">
                          {{ $t('COMMON.YES') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-authorization_server-0`" value="0" v-model="Form.authorization_server.value">
                        <label class="form-check-label" v-bind:for="`${Action}-authorization_server-0`">
                          {{ $t('COMMON.NO') }}
                        </label>
                      </div>
                      <span class="text-danger">{{ authorization_serverError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="12" v-if="Form.type.value != 2">
                    <div class="mb-3">
                      <div>
                        <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.COMMODITYAUTO') }}<span class="text-danger">*</span></label>  
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-auto-1`" value="1" v-model="Form.auto.value">
                        <label class="form-check-label" v-bind:for="`${Action}-auto-1`">
                          {{ $t('COMMON.YES') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-auto-0`" value="0" v-model="Form.auto.value">
                        <label class="form-check-label" v-bind:for="`${Action}-auto-0`">
                          {{ $t('COMMON.NO') }}
                        </label>
                      </div>
                      <span class="text-danger">{{ autoError }}</span>
                    </div>
                  </MDBCol>
              </MDBRow>
            </MDBCol> -->
            <!-- <MDBCol md="6">
              <MDBRow class="g-3">
                <MDBCol md="12">
                  <label>{{ $t(`COMMON.COMMODITYCURRENCY`) }}</label>
                </MDBCol>
                <MDBCol md="12">
                  <template v-for="(currency,k) in Form.currency.value" :key="k">
                    <MDBRow class="mb-3" v-if="(typeof Form.currency.value[k] != 'undefined')">
                      <MDBCol md="12" class="text-start">
                        <label>{{ $t(`CURRENCY.${k}`) }}({{k}})</label>
                      </MDBCol>
                      <template v-for="(item,j) in currency" :key="j">
                        <MDBRow class="mb-3" v-if="(Form.type.value != 2 || j == 1) && j!=6">
                          <MDBCol md="3" class="d-flex justify-content-end align-self-center" v-if="Form.type.value != 2">
                            {{ item.name }} <span v-if="j != 1 "> / {{$t("COMMON.MONTH")}} </span><span v-else> / 次 </span>
                          </MDBCol>
                          <MDBCol md="3" class="d-flex justify-content-end align-self-center" v-else>
                            課程 / 次
                          </MDBCol>
                          <MDBCol md="3">
                            <MDBInput
                              :label="$t(`COMMON.AMOUNT`)"
                              type="number"
                              v-model="Form.currency.value[k][j].amount"
                            />
                          </MDBCol>
                          <MDBCol md="2" class="d-none">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" v-bind:id="`${Action}-${k}-${j}-currency_status-1`" value="1" v-model="Form.currency.value[k][j].status">
                              <label class="form-check-label" v-bind:for="`${Action}-${k}-${j}-currency_status-1`">
                                {{ $t('COMMON.PUTON') }}
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="radio" v-bind:id="`${Action}-${k}-${j}-currency_status-0`" value="0" v-model="Form.currency.value[k][j].status">
                              <label class="form-check-label" v-bind:for="`${Action}-${k}-${j}-currency_status-0`">
                                {{ $t('COMMON.PUTOFF') }}
                              </label>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </template>
                    </MDBRow>
                  </template>
                </MDBCol>
                <MDBCol md="12">
                  <template v-for="(type,k) in Currency" :key="k">
                    <div v-if="(typeof Form.currency.value[type] == 'undefined')">
                      <a href="javascript:void(0)" @click="AddCurrency(type)"><MDBIcon icon="plus" iconStyle="fas" />{{ $t(`CURRENCY.${type}`) }} ({{type}})</a>
                    </div>  
                  </template>
                </MDBCol>
              </MDBRow>
            </MDBCol> -->
          </MDBRow>
        </MDBCardText>
        <MDBBtn color="primary" type="button" @click="Submit" v-if="action == 'insert' || this.action == 'copy'" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn color="primary" type="button" @click="EditSubmit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="CloseModel">
          {{ $t("COMMON.CLOSE") }}
        </MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBRow, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "CommodityForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    model: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
    // currency: {
    //   type: [Array,Object],
    //   default: function() {
    //     return ["TWD","USD","CNY"];
    //   }
    // }
  },
  data() {
    return {
      languages: i18nService.languages,
      language: i18nService.getActiveLanguage(),
      categorys: {},
      commodity: {
          0: this.$i18n.t("COMMON.ONCE"),
          1: this.$i18n.t("COMMON.MONTH1"),
          3: this.$i18n.t("COMMON.MONTH3"),
          6: this.$i18n.t("COMMON.MONTH6"),
          12: this.$i18n.t("COMMON.MONTH12"),
          14: this.$i18n.t("COMMON.FREE"),
      }
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val)
    });

    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });

    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const SubmitLoading = ref(false);
    const productnumber = ref("");

    const i18n = useI18n();

    const basicSchema = {
      name: yup.string().required().label(i18n.t("COMMON.PRODUCTNAME")),
      version: yup.string().required().label(i18n.t("COMMON.USEVERSION")),
      version_area: yup.string().required().label("版本區域"),
      currency: yup.object().label(i18n.t("COMMON.COMMODITYCURRENCY")),
      status: yup.number().required().label(i18n.t("COMMON.COMMODITYSTATUS")),
      free: yup.number().required().label(i18n.t("COMMON.COMMODITYFREESTATUS")),
      sort: yup.number().required().label(i18n.t("COMMON.SORT")),
      commodity: yup.array().label(i18n.t("COMMON.COMMODITYCURRENCY")),
      // people: yup.number().required().label(i18n.t("COMMON.COMMODITYPEOPLE")),
      // count: yup.number().required().label(i18n.t("COMMON.COMMODITYCOUNT")),
      // authorization: yup.number().required().label(i18n.t("COMMON.COMMODITYAUTHORIZATION")),
      // authorization_server: yup.number().required().label(i18n.t("COMMON.COMMODITYAUTHORIZATIONSERVER")),
      // auto: yup.number().required().label(i18n.t("COMMON.COMMODITYAUTO")),
      number: yup.string().required().label(i18n.t("COMMON.PRODUCTNUMBER")),
      soft_seq_no: yup.string().required().label("SoftSeqNo"),
      key_pro_version: yup.string().required().label("KeyProVersion"),
      version_no: yup.string().required().label("VersionNo"),
      need_write: yup.string().required().label("NeedWrite"),
    };

    let ExtendSchema = {};
    if(Action.value == "insert" || Action.value == 'copy') {
      ExtendSchema = {
        type: yup.number().required().label(i18n.t("COMMON.PRODUCTTYPE")),
      };  
    }else{
      ExtendSchema = {
      };
    }
    
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema,
      initialValues: {
        type: 1,
        status: 1,
        currency: {},
        free: 0,
        sort: 0,
        commodity: [],
        people: 1,
        count: 99,
        authorization: 0,
        authorization_server: 0,
        auto: 0,
        need_write: 0,
      }
    });

    const { value: name, errorMessage: nameError } = useField('name');
    const { value: version, errorMessage: versionError } = useField('version');
    const { value: currency, errorMessage: currencyError } = useField('currency');
    const { value: status, errorMessage: statusError } = useField('status');
    const { value: free, errorMessage: freeError } = useField('free');
    const { value: number, errorMessage: numberError } = useField('number');
    const { value: type, errorMessage: typeError } = useField('type');
    const { value: sort, errorMessage: sortError } = useField('sort');
    const { value: commodity, errorMessage: commodityError } = useField('commodity');
    // const { value: people, errorMessage: peopleError } = useField('people');
    // const { value: count, errorMessage: countError } = useField('count');
    // const { value: authorization, errorMessage: authorizationError } = useField('authorization');
    // const { value: authorization_server, errorMessage: authorization_serverError } = useField('authorization_server');
    // const { value: auto, errorMessage: autoError } = useField('auto');
    const { value: soft_seq_no, errorMessage: soft_seq_noError } = useField('soft_seq_no');
    const { value: key_pro_version, errorMessage: key_pro_versionError } = useField('key_pro_version');
    const { value: version_no, errorMessage: version_noError } = useField('version_no');
    const { value: need_write, errorMessage: need_writeError } = useField('need_write');
    const { value: version_area, errorMessage: version_areaError } = useField('version_area');

    const Form = {
      name,
      version,
      currency,
      status,
      type,
      free,
      sort,
      commodity,
      // people,
      // count,
      // authorization,
      // authorization_server,
      // auto,
      number,
      soft_seq_no,
      key_pro_version,
      version_no,
      need_write,
      version_area,
    };

    const Submit = handleSubmit(values => {
      SubmitLoading.value = true;
      ApiService.post("/Admin/v1/Commodity", values).then(response => {
        SubmitLoading.value = false;
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          resetForm();
          Model.value = false;
          GetTable();
        }
      });
    });

    const EditSubmit = handleSubmit(values => {
      SubmitLoading.value = true;
      ApiService.update("/Admin/v1/Commodity",props.uuid ,values).then(response => {
        SubmitLoading.value = false;
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          resetForm();
          Model.value = false;
          FormUUid.value = "";
          GetTable();
        }
      });
    });

    return {
      Model,
      Action,
      Form,
      versionError,
      nameError,
      statusError,
      currencyError,
      numberError,
      typeError,
      freeError,
      sortError,
      commodityError,
      // peopleError,
      // countError,
      // authorizationError,
      // authorization_serverError,
      // autoError,
      soft_seq_noError,
      key_pro_versionError,
      version_noError,
      need_writeError,
      version_areaError,
      Submit,
      EditSubmit,
      resetForm,
      FormUUid,
      SubmitLoading,
      productnumber
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    },
    "Form.free.value": function(val) {
      let keys = Object.keys(this.Form.currency.value);
      for(let i = 0;i < keys.length; i++) {
        if(typeof(this.Form.currency.value[keys[i]][6]) == 'undefined') {
          this.Form.currency.value[keys[i]][6] = {
            amount: 0,
            status: val,
          };  
        }else {
          this.Form.currency.value[keys[i]][6].status = val;  
        }
      }
    }
  },
  inject: ["Currency","Commodity"],
  methods: {
    GetDetail() {
      this.resetForm();
      this.Form.currency.value = {};
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Commodity", this.FormUUid).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "currency":
                      var tmp = {};
                      response.data.data.currency.map(item => {
                        let sub = {};
                        sub[item.category.id] = {
                          name: this.commodity[item.category.month],
                          status: item.status,
                          amount: (+item.amount)?item.amount:"",
                        };
                        if(typeof tmp[item.currency] == "undefined") {
                          tmp[item.currency] = {}
                        }
                        Object.assign(tmp[item.currency],sub);
                      });
                      Object.assign(this.Form.currency.value,tmp);
                    break;
                    case "commodity":
                      this.Form.commodity.value = response.data.data.commodity.map(item => item.uuid);
                    break;
                    case "status":
                      this.Form[k].value = 1;
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.productnumber = response.data.data.number;
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    },
    AddCurrency(type) {
      let tmp = {};
      tmp[type] = {
        1: { name: this.commodity[0],amount: "0",status: 1 },
        2: { name: this.commodity[1],amount: "0",status: 1 },
        3: { name: this.commodity[3],amount: "0",status: 1 },
        4: { name: this.commodity[6],amount: "0",status: 1 },
        5: { name: this.commodity[12],amount: "0",status: 1 },
        6: { name: this.commodity[14],amount: "0",status: this.Form.free.value },
      };
      Object.assign(this.Form.currency.value,tmp);
    },
    DeleteDownload(index){
      this.Form.downloads.value = this.Form.downloads.value.filter(function(item,key) {
        return key !== index;
      })
    },
    CloseModel() {
      this.FormUUid = "";
      this.Model = false;
    }
  }
};
</script>