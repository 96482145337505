<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader>{{ $t("COMMODITY-LIST.IMPORT-ITEM") }}</MDBCardHeader>
        <MDBCardText class="pt-3">
          <MDBRow class="g-3">
            <MDBCol md="6">
              <MDBRow>
                <MDBCol md="12">
                  <div class="mb-3">
                    <label class="form-label text-dark">選擇CSV檔案<span class="text-danger">*</span></label>
                    <MDBFile v-model="Form.excel.value"></MDBFile>
                    <span class="text-danger">{{ excelError }}</span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardText>
        <MDBBtn color="primary" type="button" @click="Submit" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="CloseModel">
          {{ $t("COMMON.CLOSE") }}
        </MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBRow, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBFile } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: "CommodityImportForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBFile
  },
  props: {
    model: {
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val)
    });

    const GetTable = inject("GetTable");
    const SubmitLoading = ref(false);

    const basicSchema = {
      excel: yup.string().required().label("檔案"),
    };
    
    const FormSchema = yup.object(Object.assign(basicSchema));

    const { handleSubmit, resetForm, setFieldValue } = useForm({
      validationSchema: FormSchema
    });

    const { value: excel, errorMessage: excelError } = useField('excel');

    const Form = {
      excel,
    };

    const Init = function(){
      setFieldValue('excel',"");
    }

    const Submit = handleSubmit(values => {
      SubmitLoading.value = true;
      values.excel = values.excel[0];
      let form = CommonService.MakeForm(values);
      ApiService.post("/Admin/v1/CommodityImport", form).then(response => {
        SubmitLoading.value = false;
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          Form.excel.value = new File();
          // resetForm();
          Model.value = false;
          Init();
          GetTable();
        }
      });
    });


    return {
      Model,
      Form,
      excelError,
      resetForm,
      SubmitLoading,
      Submit,
    };
  },
  methods: {
    CloseModel() {
      this.FormUUid = "";
      this.Model = false;
    }
  }
};
</script>